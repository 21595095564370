// import { div } from "gatsby"
import React, { Component } from "react"
import IVLogo from "../images/IV-Solution-Logo.svg"
import Phone from "../images/phone.svg"
import MenuDevice from "../components/menuDevice"
import { Link } from "gatsby"
import scrollTo from 'gatsby-plugin-smoothscroll';

class Navigation extends Component {

    constructor(props) {
        super(props);
        this.state = { showMenu: false };
        this.handleClick = this.handleClick.bind(this);
    }

    // function to handle the click
    handleClick() {
        const burgerMenu = document.querySelector('.hamburger')
        const deviceMenu = document.querySelector('.menu-device')
        burgerMenu.classList.toggle('is-active')
        deviceMenu.classList.toggle('is-open')
    };




    // the render() method to put stuff into the DOM
    render() {
        const Logo = () => <><img style={{ width: 58 }} src={IVLogo} alt='IV Solutions Logo' /></>
        const PhoneCta = () => <><img style={{ width: 29, marginRight: 9 }} src={Phone} alt='phone icon' /></>


        const Hamburger = () => (
            <button className={`hamburger hamburger--slider ${this.state.showMenu ? 'is-active' : ''}`} onClick={this.handleClick}>
                <span className="hamburger-box">
                    <span className="hamburger-inner"></span>
                </span>
            </button>

        )

        const isHomePage = typeof window !== `undefined` ? window.location.pathname : null

        const Navigation = () => (
            <div className="sticky-top no-stick--mobile" id="home">
                <div className="top-stripe d-none d-lg-flex justify-content-between align-items-center">
                    <p className="text-top">Clinically Proven IV Treatment for Depression, Anxiety, PTSD and more.</p>
                    <a className="phone-cta desktop" href="tel:+18449344863"><PhoneCta />(844) 934-4863</a>
                </div>
                <nav className="navbar sticky-top navbar-expand-lg navbar-light bg-white">
                    <Link className="d-lg-none" to="/"><Logo /></Link>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <Link className="navbar-brand d-none d-lg-block mt-2 mb-2" to="/"><Logo /></Link>
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                {
                                    isHomePage === '/' ?
                                        <div className="nav-link text-dark font-weight-bold top-nav-item" onClick={() => scrollTo('#treatment')}>Treatment</div>
                                        :
                                        <Link className="nav-link text-dark font-weight-bold top-nav-item" to='#treatment'>Treatment</Link>
                                }
                            </li>
                            <li className="nav-item">
                                {
                                    isHomePage === '/' ?
                                        <div className="nav-link text-dark font-weight-bold top-nav-item" onClick={() => scrollTo('#what-to-expect')}>What to Expect</div>
                                        :
                                        <Link className="nav-link text-dark font-weight-bold top-nav-item" to='#what-to-expect'>What to Expect</Link>
                                }
                            </li>
                            <li className="nav-item">
                                {
                                    isHomePage === '/' ?
                                        <div className="nav-link text-dark font-weight-bold top-nav-item" onClick={() => scrollTo('#testimonials')}>Testimonitals</div>
                                        :
                                        <Link className="nav-link text-dark font-weight-bold top-nav-item" to='#testimonials'>Testimonitals</Link>
                                }
                            </li>
                            <li className="nav-item">
                                {
                                    isHomePage === '/' ?
                                        <div className="nav-link text-dark font-weight-bold top-nav-item" onClick={() => scrollTo('#faq')}>FAQ</div>
                                        :
                                        <Link className="nav-link text-dark font-weight-bold top-nav-item" to='#faq'>FAQ</Link>
                                }
                            </li>
                            <li className="nav-item">
                                {
                                    isHomePage === '/' ?
                                        <div className="nav-link text-dark font-weight-bold top-nav-item" onClick={() => scrollTo('#clinical-studies')}>Clinical Research</div>
                                        :
                                        <Link className="nav-link text-dark font-weight-bold top-nav-item" to='#clinical-studies'>Clinical Research</Link>
                                }
                            </li>
                        </ul>
                        {
                            isHomePage === '/' ?
                                <div className="d-flex justify-content-between">
                                    <Link className="btn btn-secondary my-2 my-sm-0 d-none d-lg-block ml-4" to="/quiz">Neurological <br /> Health Quiz</Link>
                                    <div className="btn btn-success my-2 my-sm-0 d-none d-lg-block ml-4" onClick={() => scrollTo('#home')} style={{cursor: 'pointer'}}>Schedule a <br /> Consultation</div>
                                </div>
                                :
                                <div className="d-flex justify-content-between">
                                    <Link className="btn btn-secondary my-2 my-sm-0 d-none d-lg-block ml-4" to="/quiz">Neurological <br /> Health Quiz</Link>
                                    <Link className="btn btn-success my-2 my-sm-0 d-none d-lg-block ml-4" to="/">Schedule a <br /> Consultation</Link>
                                </div>
                        }


                    </div>
                    <Hamburger style={{ paddingTop: `10px` }} />
                </nav>
                <MenuDevice />
            </div>
        )
        return (
            <Navigation />
        );
    }
}

export default Navigation;
