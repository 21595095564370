import React from "react"
import Phone from "../images/phone.svg"


const PhoneCta = () => <><img style={{ width: 29, marginRight: 9 }} src={Phone} alt='phone icon' /></>

const TopCta = () => {
    return (
        <>
            <div className="cta-top--stripe d-flex d-lg-none justify-content-between sticky-top">
                <div className="d-flex flex-column align-items-center justify-content-center cta-bottom is-yellow p-1">
                    <a className="font-weight-bold cta-consultation--bottom" href="/">Schedule a consultation</a>
                </div>
                <a className=" phone-cta d-flex flex-column align-items-center justify-content-center cta-bottom is-purple p-2" href="tel:+18449344863">
                    <div className="font-weight-bold"><PhoneCta />(844) 934-4863</div>
                </a>
            </div>
        </>
    );
}

export default TopCta