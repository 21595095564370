/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import TopNav from "../components/topNav"
import TopCta from "../components/TopCta"
import Footer from "../components/Footer"
import { ContextProviderComponent } from "../components/ContextProvider"
import "../scss/styles.scss"


export default ({ children }) => (
    <ContextProviderComponent>
      <div style={{maxWidth:`1560px`, margin: `0 auto`, boxShadow: `0px 0px 23px -6px rgba(0,0,0,0.75)`, fontFamily: 'Montserrat, sans-serif'}} id="home">
        <TopCta />
        <TopNav />
        <div className='container-wrapper'
          style={{
            margin: `0 auto !important`,
            maxWidth: `1560px`,
            height: `100%`,
            backgroundColor: `white`,
            marginTop: 0,
          }}
        >
          <main>{children}</main>
          <footer>
            <Footer />
          </footer>
        </div>
      </div>
    </ContextProviderComponent>
  )