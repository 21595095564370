
import React from "react"
import IVLogo from "../images/IV-Solution-Logo.svg"
import Phone from "../images/phone.svg"
import Mail from "../images/mail.svg"
import Tweet from "../images/twitter.svg"
import Fb from "../images/fb.svg"
import Insta from "../images/insta.svg"
import { Link } from "gatsby"

const Logo = () => <><img style={{ width: 58 }} src={IVLogo} alt='IV Solutions Logo' /></>
const PhoneCta = () => <><img style={{ width: 29, marginRight: 10 }} src={Phone} alt='phone icon' /></>
const EmailCta = () => <><img style={{ width: 31, marginRight: 10 }} src={Mail} alt='email icon' /></>
const FbLogo = () => <><img style={{ width: 29, marginRight: 10 }} src={Tweet} alt='twitter icon' /></>
const TwitterLogo = () => <><img style={{ width: 29, marginRight: 10 }} src={Fb} alt='facebook icon' /></>
const InstaLogo = () => <><img style={{ width: 29, marginRight: 10 }} src={Insta} alt='instagram icon' /></>

const Footer = () => (
    <footer style={{ background: '#271F43', paddingBottom: '2rem' }}>
        <div className="container">
            <h2 className="is-white p-4 header-allignment" >Contact us now for a complimentary consultation</h2>
            <div className="separator mb-4"></div>
            <div className="row">
                <div className="col-sm mt-4">
                    <div className="d-flex flex-column align-items-center">
                        <Logo />
                        <h2 className="mt-2">IV Solution</h2>
                    </div>
                </div>
                <div className="col-sm mt-4">
                    <div className="d-flex flex-column align-items-center">
                        <h3> Site Map</h3>
                        <div className="separator is-half" />
                        <div className="d-flex flex-column align-items-start p-2">
                            <Link className="footer-link--item pt-2" to="/#treatment">Treatment</Link>
                            <Link className="footer-link--item pt-2" to="/#treatment">What to Expect</Link>
                            <Link className="footer-link--item pt-2" to="/#treatment">Testimonials</Link>
                            <Link className="footer-link--item pt-2" to="/#treatment">FAQ</Link>
                            <Link className="footer-link--item pt-2" to="/#treatment">Clinical Research</Link>
                        </div>
                    </div>
                </div>
                <div className="col-sm mt-4">
                    <div className="d-flex flex-column align-items-center">
                        <h3> Contact us</h3>
                        <div className="separator is-half" />
                        <div className="d-flex flex-column align-items-start p-2">
                            <a className="footer-link--item pt-2" href="tel:+18449344863"><PhoneCta />(844) 934-4863</a>
                            <a className="footer-link--item pt-2" href="mailto:"><EmailCta />info@ivsolution.com</a>
                        </div>
                    </div>
                </div>
                <div className="col-sm mt-4">
                    <div className="d-flex flex-column align-items-center">
                        <h3> Follow us</h3>
                        <div className="separator is-half" />
                        <div className="d-flex align-items-center p-2">
                            <a className="footer-link--item pt-2" href="https://twitter.com/Chi_IV_Solution" target="_blank" rel="noopener noreferrer"><TwitterLogo /></a>
                            <a className="footer-link--item pt-2" href="https://www.facebook.com/ChicagoIVSolution/" target="_blank" rel="noopener noreferrer"><FbLogo /></a>
                            <a className="footer-link--item pt-2" href="https://www.instagram.com/chicagoivsolution/" target="_blank" rel="noopener noreferrer"><InstaLogo /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
)

export default Footer
