// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-consultation-js": () => import("/opt/build/repo/src/pages/consultation.js" /* webpackChunkName: "component---src-pages-consultation-js" */),
  "component---src-pages-form-submitted-js": () => import("/opt/build/repo/src/pages/formSubmitted.js" /* webpackChunkName: "component---src-pages-form-submitted-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-quiz-js": () => import("/opt/build/repo/src/pages/quiz.js" /* webpackChunkName: "component---src-pages-quiz-js" */),
  "component---src-pages-thanks-js": () => import("/opt/build/repo/src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

