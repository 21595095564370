module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/layouts/layout.js"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WV7R6VJ","includeInDevelopment":false,"gtmAuth":" ","gtmPreview":" ","dataLayerName":"dataLayer","defaultDataLayer":{"type":"function","value":"function() {\n          return {\n            pageType: window.pageType,\n          }\n        }"}},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
